@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

$font-family-base: "OpenSans", "sans-serif" !default;
