.breadcrumb-item + .breadcrumb-item::before {
    content: "/" !important;
}

.initials{
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.nav-tabs {
    border-bottom: 2px solid #D8DBDE;
}

.nav-link:not(.active) {
    border-bottom: none;
}

.tab-content{
    padding: 15px;
    background-color: #f7f7f7;
}

.react-datepicker__input-container input{
    padding: 10px;
}