.nav-tabs .nav-link {
  font-weight: 600;
  border: none;
  background-color: $white;
  color: #617480;
  border-bottom: 2px solid var(--Gray-3, #BEC6CA);
}

.nav-tabs .nav-link:hover {
  border-bottom: 2px solid $success;
  color: $primary-base;
}

.nav-tabs {
  border:none;
  margin-bottom: 20px;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: $ptc-convergence-select-color;
  border-bottom: 2px solid $success;
  background-color: transparent;
}
