.c-header {
  background: #3B4550;
}

.main-logo {
  height: 35px;
}

.user-profile {
  color: $white;
  font-weight: 600;
}

.text-white {
  font-weight: 600;
}

.c-header-nav-items.show {
  .initials {
    border: 2px solid #197BC0;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

.c-header .c-header-toggler {
  color: $white;
}

.c-header-toggler-icon{
  height: 20px;
  width: 22px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA9SURBVHgB7dLBDQAgCEPRYhxMN9PJtcxADyTwkl57+vYIQgNi+Q8nd1GKsZoFIesOw7zDjVI8mwOh7jDuA61/E5ATFs2oAAAAAElFTkSuQmCC) !important;
}
