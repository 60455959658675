.modal-footer, .modal-header {
  border: none;
}

.modal-header {
  font-size: 1.5rem;
  line-height: 1.25rem;
  color: $white;
  font-weight: 400;
  background-color: #3B4550;
}

.close:focus-visible,.close:focus{
  outline: none !important;
  color: $white;
}

.modal-header .close {
  color: $white;
}

.rsw_2f, .rsw_3G {
  z-index: unset !important;
}

.group-membership-modal {
  height: 600px;
  overflow: scroll;
}
