.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-radius: 0;
  border-right: 0;
}

.accordion-item {
  background: $white;
  color: rgba(44,56,74,.95);
  margin-bottom: 3px;
  margin-left: 0;
  .tab-content {
    background-color: $white;
  }
  .col-11 {
    padding: 0;
  }
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.accordion-button {
  align-items: center;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  color: rgba(44,56,74,.95);
  display: flex;
  font-size: .9375rem;
  padding: 1rem 1.25rem;
  position: relative;
  text-align: left;
  width: 100%;
  margin-bottom: 2px;
}

.accordion-body {
  padding: 1rem 1.25rem;
  background-color: #fff;
  margin-left:45px;
}

.accordion-item:hover {
  .accordion-title {
    color: $ptc-convergence-input-light-gray;
  }
}

.accordion-button[aria-expanded="true"] {
  border: solid 2px $ptc-info;
  .accordion-title {
    color: $ptc-info;
  }

  .accordion-body {
    border: solid 2px red !important;
  }
}

.accordion-control {
  padding: 1rem 1.25rem;
}
