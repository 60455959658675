table tr th {
  font-weight: 600;
}

.list-group-contacts {
  max-height: 300px;
  margin-bottom: 10px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.upload-usage-report-table, .logfile-table {
  height: 400px;
  overflow: scroll;
}

.filter-options {
  h5 {
    font-size: 1.286rem;
    font-weight: 500;
    line-height: 1.333;
    color: rgb(0, 0, 0);
  }
}
