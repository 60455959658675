.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-link.btn {
  padding: 0;
}

.content-header {
  margin-bottom: 1rem;
  line-height: 0;
}

.section-header {
  margin: 20px 0 0 0;
}

.section-content {
  margin: 20px 0 20px 0;
}

.section-collapse {
  text-decoration: none !important;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
}

.icon-left {
  margin-right: 4px;
}

.icon-right {
  margin-left: 4px;
}

.no-link {
  text-decoration: none !important;
}

textarea.form-control {
  margin: 6px;
}

.toolbar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
