.toaster {
  .success {
    .toast-header {
      color: $white;
      font-weight: bold;
      background-color: $success
    }
  }
  .error {
    .toast-header {
      font-weight: bold;
      color: $white;
      background-color: $danger
    }
  }
  .info {
    .toast-header {
      font-weight: bold;
      color: $white;
      background-color: $info
    }
  }
}
