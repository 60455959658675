.badge-warning {
  color: #F1B434;
  background-color: $white;
  border: 1px solid #F1B434;
  border-radius: 3px;
}

.badge-success {
  color: #00890B;
  background-color: $white;
  border: 1px solid #00890B;
  border-radius: 3px;
}

.badge-danger {
  color: #D33E3E;
  background-color: $white;
  border: 1px solid #D33E3E;
  border-radius: 3px;
}
