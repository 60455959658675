.primary-link {
  color: $blue7;
  text-decoration: underline !important;
  font-weight: bold;
  padding: 0;
  font-size: 16px;
}

.primary-link:hover {
  color: $blue8;
}

.primary-link:disabled {
  color: $gray4;
}

.secondary-link {
  color: $gray7;
  font-weight: bold;
  padding: 0;
  font-size: 16px;
  text-decoration: underline !important;
}

.secondary-link:hover {
  color: $gray8;
}

.secondary-link:disabled {
  color: $gray4;
}
